<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row
      justify="center"
      class="pb-16 mb-16"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-stepper v-model="c1">
            <v-stepper-header>
              <v-stepper-step
                :complete="c1 > 1"
                step="1"
              >
                About You
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="c1 > 2"
                step="2"
              >
                Residence
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="c1 > 3"
                step="3"
              >
                Lifestyle
              </v-stepper-step>

              <v-divider />

              <v-stepper-step step="4">
                Health
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content step="1">
                  <v-card
                    min-height="300"
                    elevation="0"
                    class="mb-16"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      About you
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <p>
                          {{ getquestion('EN', 'firstname') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="First Name"
                          rules="required|alpha"
                        >
                          <v-text-field
                            v-model="userterm.firstname"
                            outlined
                            label="First Name"
                            color="primary"
                            :error-messages="errors"
                            @click="clickedFieldGlobal('firstName', 1, 'Term Life Survey v1')"
                          />
                        </validation-provider>
                        <p>
                          {{ getquestion('EN', 'lastname') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Last Name"
                          :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                        >
                          <v-text-field
                            v-model="userterm.lastname"
                            :error-messages="errors"
                            outlined
                            label="Last Name"
                            color="primary"
                            @click="clickedFieldGlobal('lastName', 2, 'Term Life Survey v1')"
                          />
                        </validation-provider>
                        <p>
                          {{ getquestion('EN', 'zipcode') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Zip Code"
                          rules="required|numeric|min:5|max:5"
                        >
                          <v-autocomplete
                            v-model="userterm.postcode"
                            :items="allzips"
                            :autocomplete="Date.now()"
                            :error-messages="errors"
                            counter="5"
                            label="zip code"
                            outlined
                            color="primary"
                            @change="getpostcodedata"
                            @click="clickedFieldGlobal('postcode', 3, 'Term Life Survey v1')"
                          ></v-autocomplete>
                        </validation-provider>
                        <v-snackbar
                          v-model="snackbar"
                          multi-line
                          :timeout="timeout"
                          top
                          color="primary"
                        >
                          {{ alertmessage }}
                          <v-btn
                            color="white"
                            text
                            @click="snackbar = false"
                          >
                            Close
                          </v-btn>
                        </v-snackbar>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="ma-2">
                    <v-btn
                      text
                      to="/"
                      @click="clickedFieldGlobal('Cancel', 4, 'Term Life Survey v1')"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="validLocationTab && invalid"
                      @click="c1 = 2, clickedFieldGlobal('Continue', 5, 'Term Life Survey v1')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content
                  step="2"
                  class="pb-16"
                >
                  <v-card
                    min-height="300"
                    elevation="0"
                    class="pb-16 mb-16"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Residence
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <v-dialog
                          v-model="citizenDialog"
                          max-width="500"
                          persistent
                        >
                          <v-card>
                            <v-card-text class="text-center red--text">
                              Non-U.S. citizens are not eligible for this product
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="primary white--text"
                                @click="userterm.resident = ''; citizenDialog = false; navigateHome()"
                              >
                                Accept
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <p>{{ getquestion('EN', 'citizen') }}</p>
                        <validation-provider
                          name="Citizen"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="userterm.resident"
                          >
                            <v-btn
                              active-class="aa"
                              value="yes"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('resident yes', 6, 'Term Life Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              active-class="aa"
                              value="no"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="citizenDialog = true, clickedFieldGlobal('resident no', 6, 'Term Life Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p class="pt-4">
                          Where was the insured born?
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Place"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="userterm.birthplace"
                            :items="Countries"
                            :error-messages="errors"
                            label="Select One"
                            outlined
                            color="primary"
                            @click="clickedFieldGlobal('birthplace', 7, 'Term Life Survey v1')"
                          />
                        </validation-provider>
                        <div v-if="userterm.birthplace === 'United States of America'">
                          <p>Birth State</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Birth State"
                            rules="required"
                          >
                            <v-autocomplete
                              v-model="userterm.birthstate"
                              :items="StateList"
                              :error-messages="errors"
                              label="Select One"
                              outlined
                              color="primary"
                              @click="clickedFieldGlobal('birthstate', 8, 'Term Life Survey v1')"
                            />
                          </validation-provider>
                        </div>
                        <p>{{ getquestion('EN', 'replacement') }}</p>
                        <validation-provider
                          name="Replacement"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="userterm.replacement"
                          >
                            <v-btn
                              active-class="aa"
                              value="yes"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('replacement yes', 9, 'Term Life Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              active-class="aa"
                              value="no"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('replacement no', 9, 'Term Life Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                        <p class="pt-4">
                          {{ getquestion('EN', 'income') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Income"
                          rules="required|numeric|max_value:9999999"
                        >
                          <v-text-field
                            v-model="userterm.income"
                            :error-messages="errors"
                            prefix="$"
                            outlined
                            label="Annual income"
                            color="primary"
                            @click="clickedFieldGlobal('income', 10, 'Term Life Survey v1')"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="ma-2">
                    <v-btn
                      text
                      to="/"
                      @click="clickedFieldGlobal('Cancel', 11, 'Term Life Survey v1')"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="c1 = 1, clickedFieldGlobal('Back', 12, 'Term Life Survey v1')"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="validResidenceTab && invalid"
                      @click="checkknockout('residence'), clickedFieldGlobal('Continue', 13, 'Term Life Survey v1')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content
                  step="3"
                  class="pb-16 mb-16"
                >
                  <v-card
                    min-height="300"
                    elevation="0"
                    class="pb-16 mb-16"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Your Lifestyle
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <p>
                          {{ getquestion('EN', 'nic') }}
                        </p>
                        <validation-provider
                          name="NIC"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="userterm.smoker"
                            @change="calculateUserClass"
                          >
                            <v-btn
                              active-class="aa"
                              value="yes"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('smoker yes', 14, 'Term Life Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              active-class="aa"
                              value="no"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('smoker no', 14, 'Term Life Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <p>
                          {{ getquestion('EN', 'health') }}
                        </p>
                        <validation-provider
                          name="Health"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="userterm.goodhealth"
                          >
                            <v-btn
                              active-class="aa"
                              value="yes"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('goodhealth yes', 15, 'Term Life Survey v1')"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              active-class="aa"
                              value="no"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('goodhealth no', 15, 'Term Life Survey v1')"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="ma-2">
                    <v-btn
                      text
                      to="/"
                      @click="clickedFieldGlobal('Cancel', 16, 'Term Life Survey v1')"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="c1 = 2, clickedFieldGlobal('Back', 17, 'Term Life Survey v1')"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="validLifestyleTab && invalid"
                      @click="c1 = 4, clickedFieldGlobal('Continue', 18, 'Term Life Survey v1')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-stepper-content step="4">
                  <v-card
                    min-height="300"
                    elevation="0"
                    class="pb-16 mb-16"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Your health
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <p>
                          {{ getquestion('EN', 'gender') }}
                        </p>
                        <validation-provider
                          name="gender"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="userterm.gender"
                          >
                            <v-btn
                              active-class="aa"
                              value="Male"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('gender male', 19, 'Term Life Survey v1')"
                            >
                              Male
                            </v-btn>
                            <v-btn
                              active-class="aa"
                              value="Female"
                              outlined
                              color="blanketbutton"
                              width="100"
                              @click="clickedFieldGlobal('gender female', 19, 'Term Life Survey v1')"
                            >
                              Female
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <p>
                          {{ getquestion('EN', 'height') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Height"
                          rules="required|numeric|max_value:8"
                        >
                          <v-text-field
                            v-model="userterm.feet"
                            :error-messages="errors"
                            label="Feet"
                            outlined
                            color="primary"
                            @change="calcbmi"
                            @click="clickedFieldGlobal('feet', 20, 'Term Life Survey v1')"
                          />
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Height"
                          rules="required|numeric|max_value:11"
                        >
                          <v-text-field
                            v-model="userterm.inches"
                            :error-messages="errors"
                            label="Inches"
                            outlined
                            color="primary"
                            @change="calcbmi"
                            @click="clickedFieldGlobal('inches', 21, 'Term Life Survey v1')"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                        sm="8"
                      >
                        <p>{{ getquestion('EN', 'Weight') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Weight"
                          rules="required|numeric|max_value:600"
                        >
                          <v-text-field
                            v-model="userterm.weight"
                            :error-messages="errors"
                            label="Pounds"
                            outlined
                            color="primary"
                            @change="calcbmi"
                            @click="clickedFieldGlobal('weight', 22, 'Term Life Survey v1')"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <v-row>
                          <v-col
                            cols="6"
                          >
                            <p>
                              {{ getquestion('EN', 'bday') }}  {{ displaybday }}
                            </p>
                          </v-col>
                          <v-col
                            cols="6"
                            class="d-flex flex-row-reverse"
                          >
                            Age: {{ calcage }}
                          </v-col>
                        </v-row>
                        <p
                          v-if="tooYoung"
                          class="red--text"
                        >
                          Unable to proceed. Minimum age for this product is 20.
                        </p>
                        <p
                          v-if="tooOld"
                          class="red--text"
                        >
                          Unable to proceed. Maximum age for this product is 60.
                        </p>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birthday"
                              rules="required"
                            >
                              <v-select
                                v-model="bdays"
                                :items="daysavailable"
                                label="Day"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="calcbday"
                                @click="clickedFieldGlobal('bdays', 23, 'Term Life Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Month"
                              rules="required"
                            >
                              <v-select
                                v-model="bmonths"
                                :items="monthsavailable"
                                :error-messages="errors"
                                item-text="state"
                                item-value="abbr"
                                label="Month"
                                outlined
                                color="primary"
                                @change="calcbday"
                                @click="clickedFieldGlobal('bmonths', 24, 'Term Life Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Year"
                              rules="required"
                            >
                              <v-select
                                v-model="byears"
                                :items="yearsavailable"
                                :error-messages="errors"
                                label="Year"
                                outlined
                                color="primary"
                                @change="calcbday"
                                @click="clickedFieldGlobal('byears', 25, 'Term Life Survey v1')"
                              ></v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="6"
                          >
                            <v-btn>
                              Submit Rating Information
                            </v-btn>
                            Your rating {{ rating }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="ma-2 pb-4">
                    <v-btn
                      text
                      to="/"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="c1 = 3"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="validHealthTab || invalid || tooYoung || tooOld"
                      @click="selectcoverage"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import {
    countries,
    cities,
    getCitiesByCountryCode,
  } from 'country-city-location'
  const axios = require('axios')
  const zipcodes = require('zipcodes')
  var states = require('ustates')
  export default {

    // bday en Your birth date is:
    // height en What is your height?
    // weight in lbs en What is your weight in pounds?
    // nic en In the past 5 years have you used any form of tobacco or nicotine (including e-cigs, patches or gum)?
    name: 'DashboardFormsWizard',
    data: () => ({
      citizenDialog: false,
      tooYoung: false,
      tooOld: false,
      birthStates: ['State 1', 'State 2', 'State 3'],
      birthCountries: ['United States of America'],
      toggle_exclusive: [],
      yesnoselection: '',
      answerselections: [
        'Yes', 'No',
      ],
      testswitch: false,
      testzip: '',
      years: ['2021', '2020'],
      byears: '',
      days: ['01', '02', '01', '02'],
      bdays: '',
      months: ['Jan', 'Feb', 'Mar', 'Apr', '05', '06', '07', '08', '09'],
      bmonths: '',
      toggle: [],
      snackbar: false,
      timeout: 5000,
      alertmessage: '',
      currentuser: '',
      questions: [],
      c1: 1,
      e6: 1,
      f6: 1,
      safeid: '',
      sitename: '',
      group: '',
      max: 50,
      allowSpaces: true,
      unique: true,
      userterm: {
        email: '',
        firstname: '',
        lastname: '',
        mobile: '',
        postcode: '',
        income: '',
        gender: '',
        smoker: '',
        goodhealth: '',
        resident: '',
        replacement: '',
        firsttime: '',
        bdayyear: '',
        beneficiary: '',
        bday: '',
        feet: 0,
        inches: 0,
        weight: '',
        nic: '',
        birthplace: '',
        birthstate: '',
      },

      beneficiarylist: ['Spouse', 'Child', 'Children of this marriage', 'Sibling', 'Nephew', 'Nice', 'Parent', 'Cousin', 'Synagogue', 'University', 'Bank', 'Ex-Wife', 'Church', 'Partner', 'Charity', 'Other'],
      date: null,
      menu: false,
      filterchars: true,
      onlyspace: false,
    }),
    computed: {
      Countries () {
        let items = countries.map(entry => entry.Name)
        return items
      },
      StateList () {
        let statelist = Object.values(states)
        statelist = statelist.slice(0, 51)
        let list = statelist.map(entry => entry.name)
        console.log(list)
        return list
      },
      allzips () {
        let usazips = zipcodes.radius(67052, 1500)
        console.log('codes loaded!  ', usazips[0])
        let codes = usazips.slice(0, 4)
        console.log(codes)
        return usazips
      },
      rating () {
        return this.$store.getters.getusertermlifeclass
      },
      yearsavailable () {
        let maxyear = 2021
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i < maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      displaybday () {
        let dateofbirth = '-'
        console.log(this.byears + '-' + this.bmonths + '-' + this.bdays)
        if (this.byears && this.bmonths && this.bdays) {
          dateofbirth = moment(this.byears + '-' + this.bmonths + '-' + this.bdays, 'YYYY-MMM-DD').format('DD-MMM-YYYY')
          // update age rec here
          this.userterm.bday = dateofbirth
          console.log('disp age is ', this.byears, this.bmonths, this.bdays, dateofbirth)
        } else {
          dateofbirth = '-'
        }
        return dateofbirth
      },
      calcbday () {
        let dateofbirth = '-'
        console.log(this.byears + '-' + this.bmonths + '-' + this.bdays)
        if (this.byears && this.bmonths && this.bdays) {
          dateofbirth = moment(this.byears + '-' + this.bmonths + '-' + this.bdays).format('YYYY-MM-DD')
          // update age rec here
          this.userterm.bday = dateofbirth
        } else {
          dateofbirth = '-'
        }
        return dateofbirth
      },
      calcage () {
        let age = 0
        if (this.byears && this.bmonths && this.bdays) {
          let date = moment(this.byears + '-' + this.bmonths + '-' + this.bdays)
          age = moment().diff(date, 'years')
          let currentyear = moment().format('YYYY')

          let clientage = moment().diff(moment(date, 'YYYY-MMM-DD'), 'years')
          clientage < 20 ? this.tooYoung = true : this.tooYoung = false
          clientage > 60 ? this.tooOld = true : this.tooOld = false
          return clientage
        }
        age < 20 ? this.tooYoung = true : this.tooYoung = false
        age > 60 ? this.tooOld = true : this.tooOld = false
        return age
      },
      validLocationTab () {
        let blockuser = true
        console.log('post data ', this.userterm.postcode)
        if (
          // mrmr
          this.userterm.firstname &&
          this.userterm.lastname &&
          this.userterm.postcode &&
          this.userterm.postcode.length === 5 &&
          this.$store.getters.postcodedata.country_code === 'US'
        ) {
          blockuser = false

          // maybe they changed it so just update it again
          this.$store.dispatch('checkpostcode', this.userterm.postcode)
        }
        return blockuser
      },
      validResidenceTab () {
        let blockuser = true
        if (
          // mrmr
          this.userterm.resident &&
          this.userterm.income
        ) {
          blockuser = false
        }

        return blockuser
      },
      validLifestyleTab () {
        let blockuser = true
        if (
          this.userterm.smoker &&
          this.userterm.goodhealth
        ) {
          blockuser = false
          // set globals
        }
        return blockuser
      },
      validHealthTab () {
        let blockuser = true
        if (
          this.userterm.feet &&
          this.userterm.weight &&
          this.userterm.bday &&
          this.userterm.gender
        ) {
          blockuser = false
          // set globals
        }
        return blockuser
      },

    },
    watch: {
      sitename: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    created () {
      this.calculateUserClass()
      console.log('user is ', this.$store.getters.user)
      this.$store.dispatch('loadtermlifequestions')
      let usazips = zipcodes.radius(67052, 1500)
      let codes = usazips.slice(0, 4)
      console.log('codes loaded!  ', usazips[0], codes)

      if (this.$store.getters.user) {
        console.log('$$$$$ SETTING USER DATA')
        this.userterm = this.$store.getters.user
        this.userterm.feet = this.$store.getters.user.height.feet
        this.userterm.inches = this.$store.getters.user.height.inches
      }
      this.userterm.postcode = this.$store.getters.user.postcode
      if (this.$store.getters.user.postcode) {
        this.getpostcodedata(this.$store.getters.user.postcode)
        // this.$store.dispatch('checkpostcode', this.$store.getters.user.postcode)
      }
      if (this.$store.getters.user.dob) {
        this.userterm.bday = this.$store.getters.user.dob
        // set day set month set year
        this.byears = moment(this.$store.getters.user.dob).format('YYYY')
        this.bmonths = moment(this.$store.getters.user.dob).format('MMM').toUpperCase()
        this.bdays = moment(this.$store.getters.user.dob).format('DD')
      }
      if (this.userterm.inches && this.userterm.feet && this.userterm.weight) {
        this.calcbmi()
      }
      this.$gtag.pageview('SurveyTermLife')
      this.$gtag.event('InitialSurveyTermLife', { action: 'surveyStarted' })
      this.$store.dispatch('loadtermlifequestions')
      if (this.$store.getters.getTermLifeQuestions) {
        this.questions = this.$store.getters.getTermLifeQuestions
      }
    },
    beforeDestroy () {
      this.calcbmi()
      if (this.userterm.smoker === 'yes') {
        this.$store.dispatch('setUserNic', { nic: true })
      }
      if (this.userterm.smoker === 'no') {
        this.$store.dispatch('setUserNic', { nic: false })
      }
      this.$store.dispatch('setUserGender', { gender: this.userterm.gender })
      let age
      if (this.byears && this.bmonths && this.bdays) {
        let date = moment(this.byears + '-' + this.bmonths + '-' + this.bdays)
        age = moment().diff(date, 'years')
        this.$store.dispatch('setUserAge', { age: age })
      }
      let rec = {
        age: age,
        birthdate: this.$store.getters.getapplieddetails.bday,
        gender: this.userterm.gender,
        policyclassification: this.$store.getters.getusertermlifeclass,
        smoker: this.$store.getters.getnic,
        termlength: '10',
        faceamount: 250000
      }
      console.log('bday', this.$store.getters.getapplieddetails.bday)
      console.log('calling getTermLifeProtectivePriceDb from survey term life ln 798 ', rec)
      this.$store.dispatch('calculateTermLifeSymetraPrice', rec)
      this.userterm = ''
    },
    methods: {
      navigateHome () {
        this.$router.push({ path: '/' })
      },
      checkknockout (tabname) {
        console.log('knock check', tabname, this.userterm.resident)
        let knockout = false
        if (tabname === 'residence') {
          if (this.userterm.resident === 'no') {
            console.log('citizen is ', this.userterm.resident)
            knockout = true
            this.c1 = 3
          } else {
            this.c1 = 3
          }
        }
        if (knockout) {
          this.$router.push({ path: '/SurveySpecialHandling' })
        }
      },
      recordNic (val) {

      },
      recordGender (val) {

      },
      tagquestion (value) {
        this.$gtag.event('InitialSurveyTermLife', { action: value })
      },
      getpostcodedata (postcode) {
        console.log('looking zip code data ')

        let loc = zipcodes.lookup(postcode)
        console.log('zip code data ', loc)
        this.$store.dispatch('checkpostcode', postcode)
        this.e6 = '2'
        this.$gtag.event('InitialSurveyTermLife', { action: 'Postcode' })
        console.log(this.$store.getters.postcodedata)
        if (postcode.length !== 5) {
          this.alertmessage = 'Please enter a valid zipcode'
          this.snackbar = true
        }
      },
      getquestion (language, label) {
        let questiontext = 'unknown'
        if (!this.questions) {
          return
        }
        questiontext = this.questions.find(entry => {
          if (entry.label === label) {
            return true
          }
        })
        if (!questiontext) {
          return 'this I do not know'
        }
        return questiontext.question
      },
      calculateUserClass () {
        let smokestatus
        if (this.userterm.smoker === 'yes') {
          this.$store.dispatch('setUserNic', { nic: true })
          smokestatus = true
        }
        if (this.userterm.smoker === 'no') {
          this.$store.dispatch('setUserNic', { nic: false })
          smokestatus = false
        }
        console.log('trying to get rating !!!!')
        this.$store.dispatch('calcProtClass', { smoker: smokestatus, feet: this.userterm.feet, inches: this.userterm.inches, weight: this.userterm.weight })
      },
      calcbmi () {
        let statecode = ''
        console.log('calcing bmi and class')
        let smokestatus
        statecode = this.$store.getters.postcodedata.state_code
        if (this.userterm.smoker === 'yes') {
          this.$store.dispatch('setUserNic', { nic: true })
          smokestatus = true
        }
        if (this.userterm.smoker === 'no') {
          this.$store.dispatch('setUserNic', { nic: false })
          smokestatus = false
        }
        this.$store.dispatch('calcProtClass', { smoker: smokestatus, feet: this.userterm.feet, inches: this.userterm.inches, weight: this.userterm.weight })
        this.$gtag.event('InitialSurveyTermLife', { action: 'bmi' })
      },
      selectcoverage () {
        // calc BMI
        let totalinches = (parseInt(this.userterm.feet * 12)) + parseInt(this.userterm.inches)
        // incase they changed it in the form
        let postcode = this.userterm.postcode
        this.$store.dispatch('checkpostcode', postcode)

        this.$store.dispatch('recordOptions', this.userterm)
        this.$gtag.event('InitialSurveyTermLife', { action: 'surveyComplete' })
        let years = moment().diff(this.userterm.bday, 'years')
        console.log('@@@@@@   age is ', years)
        if (years > 70 || years < 18) {
          this.$router.push({ path: '/SurveySpecialHandling' })
        } else {
          console.log('@@@@@@  reg path age is ', years)
          this.$router.push({ path: '/TermCoverage' })
        }
      },
      save (date) {
        this.$refs.menu.save(date)

        this.userterm.bday = date
        this.$gtag.event('InitialSurveyTermLife', { action: 'birthDay' })
      }
    }
  }
</script>

<style scoped>
.aa {
  background: #f5f8f5 !important;
}

.bb {
  background: green !important;
}

.cc {
  background: blue !important;
}

.dd {
  background: yellow !important;
}
.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
.blanketheader {
    font-family: 'formadjrdeckregular';
    color: 'red';
    font-size:1px;
    justify-content: center;
    text-align: center;
}
.rounded-card{
    border-radius:50px;
    background: "red";
}
.button-sections {
    display: flex;
    text-align: center;
    margin: 20px 0 0 0;
    font-size:18px;
    justify-content: center;
}

.button-sections > .yes {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #129fb1;
    padding: 12px 50px;
    margin: 1px;
    background: #129fb1;
    color: #fff;
}

.button-sections > .no {
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    color: #009db3;
    margin: 1px;
    border: 1px solid #129fb1;
    padding: 12px 50px;

}

.button-sections > .yes:hover {
    background: #005f6c;
    border: 1px solid #005f6c;
}

.button-sections > .no:hover {
    background: #eee;
}
</style>
<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
